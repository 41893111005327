.legend-container {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    position: sticky;
    bottom: 20px; /* Adjust this value to align with the feedback button */
    z-index: 1000; /* Ensure it stays on top */
    width: 100%;
    background-color: white;
}

.legend-item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;  
}

.legend-symbol-container {
    display: flex;
    align-items: center;
    gap: 5px; /* Space between symbol and text */
}

.legend-circle {
    display: inline-block;
    border-radius: 50%;
}

.high-occurrence {
    width: 15px;
    height: 15px;
    background-color: #555;
}

.low-occurrence {
    width: 10px;
    height: 10px;
    background-color: #cccccc;
}

.no-occurrence {
    width: 10px;
    height: 10px;
    background-color: #ff0000;
}

.legend-dot {
    color: #555;
    font-size: 14px;
}

.legend-text {
    font-size: 12px;
    color: #333;
}