/* Style for the main container of the text data display */
.textDataDisplay {
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  border-radius: 10px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for each data row */
.dataRow {
  display: flex;
  align-items: center; /* Align items in the center vertically */
  gap: 10px; /* Add space between the items in the row */
  margin-bottom: 10px;
}

/* Style for the labels */
.label {
  color: #e20000; /* SBB red for labels */
  white-space: nowrap; /* Prevent label from wrapping to a new line */
}

/* Style for the entries */
.entry, .entry-inline {
  color: #000000; /* Black color for entries */
  display: inline-block; /* Ensure it stays inline with the label */
  margin-left: 0px; /* No indent for entries */
}

/* Style for the container holding system data rows, organized in a grid */
.systemDataRow {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px; /* Adjust the gap to create space for the feedback column */
}

/* Style for each level row, ensuring it takes up the necessary space */
.levelRow {
  display: contents;
}

/* Style for the system level column */
.systemLevel {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap between rows as needed */
}

/* Fixed height for each correct row to prevent vertical adjustment */
.correctRow {
  display: flex;
  flex-direction: row; /* Align buttons and feedback row horizontally */
  align-items: center; /* Align items in the center vertically */
  gap: 10px;
  margin-bottom: 10px;
  height: 40px; /* Set a fixed height to ensure the row doesn't adjust */
}

/* Style for the correct buttons container */
.correctButtons {
  display: flex;
  gap: 10px;
  width: 150px;
  margin-bottom: 0px; /* No margin-bottom to align with the entry */
  flex-direction: row; /* Ensure buttons are next to each other */
  justify-content: flex-start; /* Align buttons to the start (left) */
  margin-left: -10px; /* Move the buttons 10px to the left */
}

/* Style for the correct buttons */
.correctButtons button {
  padding: 5px 10px;
  background-color: #F6F6F6;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Style for the correct buttons when hovered or active */
.correctButtons button:hover, .correctButtons button.active {
  background-color: #e20000; /* Darker shade when hovered or active */
  color: #ffffff; /* White text when active */
}

/* Style for the dropdowns */
.dropdown {
  width: 200px; /* Set a fixed width for all dropdowns */
  max-width: 100%; /* Ensure it doesn't overflow the container */
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 12px;
}

/* Style for the container holding all the data rows */
.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;  /* Required to position the sticky button */
  height: 100%;  /* Ensures the container takes full height */
}

/* Style for the feedback button */
.feedbackButton {
  padding: 10px 20px;
  background-color: #e20000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Make the button stretch across the container */
  text-align: center; /* Center the text */
  position: sticky; /* Make the button sticky */
  bottom: 0; /* Stick to the bottom of the container */
  margin-top: auto;  /* Pushes the button to the bottom of the container */
}

/* Style for the feedback button when hovered or active */
.feedbackButton:hover, .feedbackButton.active {
  background-color: #444444; /* Darker shade when hovered or active */
}

/* Style for the sticky header */
.sticky-header {
  position: sticky;
  top: -20px; /* Move the header 20px above the top of the container for closer alignment */
  background-color: rgba(255, 255, 255, 0.9); /* Translucent white background */
  backdrop-filter: blur(10px); /* Apply a blur effect */
  z-index: 100; /* Ensure it stays above other content */
  padding: 10px 20px; /* Adjust padding for better spacing */
  margin: -20px -20px 10px -20px; /* Increase negative top margin for better alignment */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation effect */
  border-bottom: 1px solid #ddd; /* Optional: add a bottom border for separation */
}

/* Adjustments to label and entry styling for better alignment */
.sticky-header .label {
  color: #e20000; /* SBB red for labels */
  font-size: 24px; /* Slightly larger font size */
  white-space: nowrap; /* Prevent label from wrapping to a new line */
}

.sticky-header .entry {
  color: #333; /* Darker text color for contrast */
  font-size: 24px; /* Match the label font size */
  display: inline-block; /* Ensure it stays inline with the label */
  margin-left: 10px; /* Add some space between label and entry */
}