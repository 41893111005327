/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General Styling */
html, body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 13px; /* Specify units */
  color: var(--sbb-color-text);
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  overflow: hidden; /* Prevent scrolling on the whole page */
  height: 100vh; /* Ensure body takes full viewport height */
}

/* App Container */
.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: none;
}

/* Header Styling */
.App-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px; /* Adjusted for consistent padding */
  box-sizing: border-box;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd; 
}

.logo-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logo {
  height: 40px;
  object-fit: contain; /* Ensure the logos fit within their container */
}

.logo:first-child {
  margin-right: auto;
}

.logo:last-child {
  margin-left: auto;
}

/* Main Content Layout */
.main-content {
  display: flex;
  width: 100%;
  height: calc(100% - 80px);
  padding: 20px; /* Consistent padding */
  box-sizing: border-box;
  gap: 10px; /* Space between columns */
  overflow: hidden; /* Ensure the content fits within the viewport */
}

/* Columns Styling */
.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative; /* Make sure the elements inside can be positioned relative to this container */
}

/* Adjust the first column to take less space */
.main-content .column:first-child {
  flex: 0.45;
}

/* Adjust the second column to take more space */
.main-content .column:last-child {
  flex: 0.55;
}

/* Scatter Plot Styling */
.scatter-plot-container {
  flex: 5;
  display: flex; /* Ensure it acts as a flex container */
  align-items: center; /* Vertically centers the content */
  justify-content: center; /* Horizontally centers the content */
  height: 100%; /* Ensure it takes full height of the parent */
  padding: 0; /* Remove any padding */
  margin: 0; /* Remove any margin */
}

/* Tree Container Styling */
.tree-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 80px; /* Add space for the sticky legend */
}

.tree-container > div:first-child {
  flex: 1;
  overflow-y: auto;
}

/* TextDataDisplay Styling */
.textdata-container {
  flex: 6;
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding: 0px; /* Add some padding if needed */
}

/* Axis Labels */
.x-axis-label,
.y-axis-label {
  font-family: inherit; /* Inherit from the global font-family */
  fill: #333;
}

/* Axis Note */
.axis-note {
  font-family: inherit; /* Inherit from the global font-family */
  fill: #555;
}

/* Section Title */
.section-title {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #e20000; /* SBB red */
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9); /* Translucent white background */
  backdrop-filter: blur(10px); /* Apply a blur effect */
  z-index: 100; /* Ensure it stays above other content */
  padding: 10px 20px; /* Adjust padding for better spacing */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation effect */
  border-bottom: 1px solid #ddd; /* Optional: add a bottom border for separation */
  cursor: default; /* Ensure the cursor remains the default arrow */
}

/* Feedback Container */
.feedback-container {
  position: sticky;
  bottom: 20px; /* Make it sticky at the bottom */
  padding: 20px;
  background-color: white;
  z-index: 1000;
}

/* Legend Container */
.legend-container {
  position: sticky;
  bottom: 20px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  display: flex;
  justify-content: space-between; /* Evenly distribute legend items */
  align-items: center;
  z-index: 0;
  background-color: white;
  margin-top: auto;  /* Ensures the legend stays at the bottom */
}

/* Legend Item */
.legend-item {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjusted for better spacing */
}

/* Legend Symbols */
.legend-symbol-container {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between symbol and text */
}

.legend-circle {
  display: inline-block;
  border-radius: 50%;
}

.high-occurrence {
  width: 15px;
  height: 15px;
  background-color: #555;
}

.low-occurrence {
  width: 10px;
  height: 10px;
  background-color: #cccccc;
}

.no-occurrence {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
}

.legend-dot {
  color: #555;
  font-size: 14px;
}

.legend-text {
  font-size: 12px;
  color: #333;
}

/* Tooltip Styling */
.tooltip {
  position: absolute;
  visibility: hidden;
  background: #f9f9f9;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  color: #333;
  z-index: 1000; /* Ensure the tooltip appears above other elements */
}

/* Adjustments to label and entry styling for better alignment */
.sticky-header .label {
  color: #e20000; /* SBB red for labels */
  font-size: 24px; /* Slightly larger font size */
  white-space: nowrap; /* Prevent label from wrapping to a new line */
}

.sticky-header .entry {
  color: #333; /* Darker text color for contrast */
  font-size: 24px; /* Match the label font size */
  display: inline-block; /* Ensure it stays inline with the label */
  margin-left: 10px; /* Add some space between label and entry */
}

